import React, { Component } from 'react';

class EMail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formEmail: ""
    }

    this.handleFormInput = this.handleFormInput.bind(this);
  }

  render() {
    return (
      <div id="" className='rounded text-base text-gray-700 bg-white rounded p-2 md:p-5 md:self-end border-t-8 border-yellow-400 bg-gradient-to-br from-yellow-200 to-yellow-400 '>

        <form action="https://gmail.us20.list-manage.com/subscribe/post?u=2c738a6289f06aedaf9dbe04b&amp;id=b87566f666" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate my-2 p-2" target="_blank" noValidate>

          <div>
            <label htmlFor="mce-EMAIL" className='text-left md:text-lg block font-bold mb-4'>Subscribe for more tutorials and ggplot2 insights!</label>
            <p className='text-left text-yellow-800 text-sm'>Don't worry. You will not get many mails. I write mails only when I have new interesting content for you. </p>

            <div className='flex flex-col md:flex-row my-5'>
              
              <input type="email" value={this.state.formEmail}
                onChange={this.handleFormInput}
                name="EMAIL" className="bg-yellow-50text-left p-2 md:p-4 text-lg md:h-16 rounded md:w-2/3 border mb-3" id="mce-EMAIL" placeholder="email address" required></input>

              <input type="submit" value="Subscribe" name="subscribe" className="md:h-16 bg-pink-500 text-pink-50 rounded p-2 md:ml-2 cursor-pointer hover:bg-pink-600" />
            </div>

            
            <div style={{position: `absolute`, left: `-5000px`}} aria-hidden="true">
              <input type="text" name="b_2c738a6289f06aedaf9dbe04b_b87566f666" tabIndex="-1"
              onChange={this.handleFormInput} value={this.state.formEmail} />
            </div>
          </div>

        </form>
      </div>
    );
  }

  handleFormInput(event) {
    this.setState({formEmail: event.target.value});
  }
}

export default EMail;