import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components';
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import {MdDateRange} from 'react-icons/md'
import tw from "twin.macro"


const CardContainer = styled.div`
 width: 100%;

 @media only screen and (min-width: 640px) {
   width: calc(50% - 20px);
   margin-right: 20px;
 }

 @media only screen and (min-width: 1024px) {
   width: calc(25% - 20px);
   margin-right: 20px;
 }
`

const Tutorial = (data) => {
    const tut = data.data.frontmatter;
  
    const classNames = `shadow-lg mb-10 hover:shadow-md cursor-pointer rounded-md bg-white ${data.borderColor}`
  
    return (
    
      <CardContainer className={classNames}>
        <Link to={`/tutorials/${data.data.frontmatter.slug}`}>
          <GatsbyImage className='h-56 max-h-full w-100 rounded-t-md' image={tut.thumbnail.childImageSharp.gatsbyImageData} />
  
          <div className="bg-white rounded-b-md">
            <h2 className='pt-3 px-4 text-base font-bold'>{tut.title}</h2>
            <p className='px-4 pt-1 text-sm'>{tut.description}</p>
            <p className='px-4 text-xs pb-4 pt-3 text-gray-400 flex items-center'><MdDateRange className='mr-1 text-gray-400' /> Last update: {tut.date}</p>
          </div>
  
        </Link>
      </CardContainer>
    )
  }


export default Tutorial