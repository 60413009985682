import React from "react"
import { graphql, Link } from 'gatsby'
import Shell from '../components/global/shell'
import Container from '../components/global/container'
import styled from 'styled-components'
import EMailSubscription from '../components/global/emailshort'
import CourseCard from '../components/courses/fundamentals/coursecard'
import tw from "twin.macro"
import dots from "../images/highlights/yellow_dots2.svg"
import NewsNotification from "../components/global/newsnotification"
import Tutorial from '../components/global/tutorialcard'

const Columns = styled.div`
  column-count: 1;

  @media (min-width: 768px) {
    column-count: 2;
  }

  @media (min-width: 1024px) {
    column-count: 3;
  }

`


const HeadingGradient = styled.h2`
  ${tw`bg-gradient-to-r from-pink-100 to-yellow-50`};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const Hero = styled.div`
  background: url(${dots});
  background-repeat: repeat;
`

const EMailBox = styled.div`
  /* margin-top: -50px; */
`;

const borderColors = [
  'border-yellow-500',
  'border-yellow-400',
  'border-yellow-300', 
  'border-yellow-200',  
  'border-pink-500',
  'border-pink-400',
  'border-pink-300',
  'border-pink-200'
]


const EMailSub = () => {
  return(
    <div className="bg-grey-100 py-10">
      <Container>
        <EMailBox className="w-full md:w-6/12 mx-auto">
          <EMailSubscription />
        </EMailBox>
      </Container>
    </div>
  )
}

const NewCourse = () => {
  return(
    <div className="bg-gray-50 py-8">
    <Container className="">
      <div className="my-5 md:my-10 flex flex-row justify-center">
        <div className="w-100 md:w-1/3">
          <p className="text-base md:text-lg pb-5">If you feel you need to brush up on your ggplot2 skills or need a thorough introduction to the major topics of ggplot2, check out my new course:</p>
          <CourseCard />
          
        </div>
      </div>
    </Container>
    </div>
  )
}

const LandingPage = (props) => {

  const tutorials = props.data.tutorials.edges;

  return (
    <Shell >
      <NewsNotification />

      <div className="bg-gradient-to-r from-pink-500 to-yellow-500">
      <Hero className="">
        
        
        <Container>
          <div className="">
            
            <div className='py-10 md:py-32'>
              <div className=''>
                <HeadingGradient className='font-bold text-2xl md:text-5xl text-gray-900'>Get really good at ggplot2</HeadingGradient>
                <p className='text-lg md:text-3xl text-pink-100 pt-3 lg:w-2/3'>Upskill your ggplot2 skills with tutorials, educational apps, and courses</p>
              </div>
{/* 
              <div className="flex flex-row mt-10">
                <Link to="/courses/ggplot2fundamentals" className=" bg-yellow-400 hover:bg-yellow-300 p-4 text-yellow-900 rounded">Have a look at our new ggplot2 course</Link>

              </div> */}
            </div>

          </div>
        </Container>

        
      </Hero>
      </div>


      <EMailSub />

      <Container>

        <h2 className="text-center font-bold text-lg md:text-xl py-10 text-gray-800">Latest Tutorials</h2>

        <div className='pb-12 pb-12 flex flex-wrap'>
          {tutorials.map((e, i) => {

            const borderColor = borderColors[Math.floor(Math.random() * borderColors.length)];
            return <Tutorial key={i} data={e.node} borderColor={borderColor} />
          })}
        </div>
      </Container>

      <NewCourse />


    </Shell>
  )
}

export default LandingPage



export const pageQuery = graphql`
  query MyQuery {
    tutorials: allMdx(filter: {fileAbsolutePath: {regex: "/viz-pages/"}},
           sort: {fields: frontmatter___date, order: DESC}, limit: 8) {
      edges {
        node {
          slug
          frontmatter {
            title
            slug
            description
            date(formatString: "MMMM Do, YYYY")
            thumbnail {
              childImageSharp {
                gatsbyImageData(width: 1000)
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;