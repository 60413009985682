import React from 'react'
import {Link} from 'gatsby'

const NewsNotification = (props) => {
  return (
    <Link to="/courses/ggplot2fundamentals" className="bg-white invisible lg:visible fixed top-2/3 right-4 p-4 text-sm shadow-lg rounded-md cursor-pointer hover:bg-yellow-50 z-50">
        <p className="text-gray-700 w-32">❤️ Hi, can I show you a new course I've been working on?</p>
    </Link>
  )
}

export default NewsNotification


